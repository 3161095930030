import { utils, Utils } from '../../utils/webUtils';
import {
  window,
  pgVersionManager,
  translationUtils,
  experimentsWrapper,
  TPADimensionsHelper,
} from '@wix/photography-client-lib';
import { GALLERY_CONSTS } from 'pro-gallery';

export default class SiteHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;
    this.isAlbumStore = !!isStoreGallery && !!props.isAlbumsStore;
    this.update = this.update.bind(this);
    this.parseViewMode = this.parseViewMode.bind(this);
    this.handleNewGalleryStructure = this.handleNewGalleryStructure.bind(this);
    this.getScrollingElement = this.getScrollingElement.bind(this);
    this.getPGStyles = this.getPGStyles.bind(this);
    this.isPremiumSite = this.isPremiumSite.bind(this);
    this.isMobile = this.isMobile.bind(this);
    this.getStylesForProvider = this.getStylesForProvider.bind(this);
    this.getPreviewMobileEmulatorWidth = this.getPreviewMobileEmulatorWidth.bind(
      this,
    );
    this.getPreviewMobileEmulatorLeft = this.getPreviewMobileEmulatorLeft.bind(
      this,
    );
  }

  update(props) {
    this.galleryWrapperProps = props;

    this.updateVersionManagerIfNeeded();

    translationUtils.setTranslations(this.galleryWrapperProps.translations);
    experimentsWrapper.setExperiments({
      ...this.galleryWrapperProps.experiments,
    });

    utils.updateViewMode(this.galleryWrapperProps.viewMode);

    if (window.isSSR) {
      const isMobile = this.isMobile();
      window.deviceType = isMobile ? 'mobile' : 'desktop';
      utils.setIsWixMobile(isMobile);
    }
  }

  parseViewMode(viewMode) {
    if (this.galleryWrapperProps.isInSEO) {
      return GALLERY_CONSTS.viewMode.SEO;
    }
    return Utils.parseViewMode(viewMode);
  }
  parseFormFactor(formFactor) {
    return Utils.parseFormFactor(formFactor);
  }

  handleNewGalleryStructure(newGalleryStructureData) {
    const {
      numOfItems,
      container,
      styleParams,
      layoutHeight,
      isInfinite,
      updatedHeight,
    } = newGalleryStructureData;

    const setHeightImp = (newHeight) => {
      if (typeof this.galleryWrapperProps.setHeight === 'function') {
        if (utils.isVerbose()) {
          console.log('Setting new height for gallery', newHeight);
        }
        this.galleryWrapperProps.setHeight(newHeight);
      }
    };

    TPADimensionsHelper.setWixHeight({
      height: layoutHeight,
      offsetTop: 0,
      styleParams,
      container,
      numOfItems,
      isInfinite,
      updatedHeight,
      setHeightImp,
      viewMode: this.galleryWrapperProps.viewMode,
    });

    try {
      this.galleryWrapperProps.updateLayout();
    } catch (e) {
      console.log('Cannot call updateLayout', e);
    }
  }

  updateVersionManagerIfNeeded() {
    const { dateCreated, gallerySettings } = this.galleryWrapperProps;
    try {
      const _dateCreated = Date.parse(dateCreated);
      if (pgVersionManager.dateCreated !== _dateCreated) {
        if (_dateCreated) {
          pgVersionManager.setDateCreated(_dateCreated);
          if (gallerySettings) {
            let _gallerySettings = gallerySettings;
            if (this.IsJsonString(_gallerySettings)) {
              _gallerySettings = JSON.parse(gallerySettings);
            }
            const galleryUpgrades = _gallerySettings.upgrades;
            pgVersionManager.update(null, galleryUpgrades);
          }
          if (utils.isDev()) {
            window.dateCreated = dateCreated;
          }
        }
      }
    } catch (e) {
      console.error('Could not update version manager', e);
    }
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getScrollingElement() {
    if (typeof this.scrollingElement === 'object') {
      return this.scrollingElement;
    } else if (
      typeof this.galleryWrapperProps.registerToScroll === 'function'
    ) {
      this.scrollingElement = {
        addEventListener: (eventName, callback) => {
          this.galleryWrapperProps.registerToScroll(callback);
        },
        removeEventListener: () => {},
        scrollTo: this.galleryWrapperProps.scrollTo,
      };
      return this.scrollingElement;
    } else {
      return {
        addEventListener: () => {},
        removeEventListener: () => {},
        scrollTo: this.galleryWrapperProps.scrollTo,
      };
    }
  }

  getPGStyles() {
    return this.galleryWrapperProps.styles;
  }

  getPreviewMobileEmulatorWidth() {
    if (this.galleryWrapperProps.styles.responsive) {
      // editorX
      return this.getPreviewMobileEmulatorBoundingClientRect().width;
    } else {
      // classic editor - no need to calc as it is always 320
      return 320;
    }
  }

  getPreviewMobileEmulatorLeft() {
    if (this.galleryWrapperProps.styles.responsive) {
      // editorX - no need to calc as it is always 0
      return 0;
    } else {
      // classic editor
      return this.getPreviewMobileEmulatorBoundingClientRect().left;
    }
  }

  getPreviewMobileEmulatorBoundingClientRect() {
    try {
      if (!this.siteContainerElement) {
        this.siteContainerElement = window.document.getElementById(
          'SITE_CONTAINER',
        );
      }
      return this.siteContainerElement.getBoundingClientRect();
    } catch (e) {
      console.error(e);
    }
  }

  getStylesForProvider() {
    if (!this.isStoreGallery) {
      return {};
    } else {
      const { styleParams } = this.galleryWrapperProps;
      return styleParams
        ? Object.assign({}, styleParams.colors, styleParams.fonts)
        : {};
    }
  }

  isPremiumSite() {
    const { baseUrl } = this.galleryWrapperProps;
    return baseUrl && baseUrl.includes && !baseUrl.includes('.wixsite.com'); // this is a hack until we have a normal way to know
  }

  isMobile() {
    return this.galleryWrapper.formFactor === GALLERY_CONSTS.formFactor.MOBILE;
  }
}
