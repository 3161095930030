import { utils } from '../../utils/webUtils';
import { window } from '@wix/photography-client-lib';

export default class AccessibilityHelper {
  constructor(galleryWrapper, props) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;

    this.update = this.update.bind(this);
    this.initAccessibility = this.initAccessibility.bind(this);
    this.cleanupAccessibility = this.cleanupAccessibility.bind(this);
    this.waitForFirstTabPress = this.waitForFirstTabPress.bind(this);

    this.isDevAccessibility = utils.shouldDebug('accessibility');
    this.tabWasPressed = false;
    this.isAccessible = false;
  }

  update(props) {
    this.galleryWrapperProps = props;

    if (
      this.galleryWrapper.state.isAccessible !==
      ((this.galleryWrapperProps.accessibilityEnabled ||
        this.isDevAccessibility) &&
        this.tabWasPressed)
    ) {
      const isAccessible =
        (this.galleryWrapperProps.accessibilityEnabled ||
          this.isDevAccessibility) &&
        this.tabWasPressed;
      if (this.isAccessible !== isAccessible) {
        this.parent.clientSideFunctionsConnectedPromise.promise.then(() => {
          // This makes sure we are not calling worker functions before they were set in the client side worker
          this.parent.props.onDimensionsReady(this.getDimensions());
        });
        this.isAccessible = isAccessible;
        this.galleryWrapper.clientSideFunctionsConnectedPromise.promise.then(
          () => {
            // This makes sure we are not calling worker functions before they were set in the client side worker
            this.galleryWrapper.props.onAccessibilityChanged(isAccessible);
          },
        );
      }
    }
  }

  initAccessibility() {
    if (
      this.galleryWrapperProps.accessibilityEnabled ||
      this.isDevAccessibility
    ) {
      window.addEventListener('keydown', this.waitForFirstTabPress);
    }
  }

  cleanupAccessibility() {
    if (
      this.galleryWrapperProps.accessibilityEnabled ||
      this.isDevAccessibility
    ) {
      window.removeEventListener('keydown', this.waitForFirstTabPress);
    }
  }

  waitForFirstTabPress = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      window.removeEventListener('keydown', this.waitForFirstTabPress);
      this.tabWasPressed = true;
      this.isAccessible = true;
      this.galleryWrapper.clientSideFunctionsConnectedPromise.promise.then(
        () => {
          // This makes sure we are not calling worker functions before they were set in the client side worker
          this.galleryWrapper.props.onAccessibilityChanged(true);
        },
      );
      return false;
    }
  };
}
