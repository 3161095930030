import React from 'react';
import { GALLERY_CONSTS } from 'pro-gallery';
import { utils } from '../../utils/webUtils';
import { experimentsWrapper } from '@wix/photography-client-lib';
import {
  EXTERNAL_INFO_TYPE,
  TextInfoElement,
} from '@wix/pro-gallery-info-element';

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
export default class ItemsHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;
    this.update = this.update.bind(this);
    this.pgItemsProps = this.pgItemsProps.bind(this);
    this.areOneOrMoreItemsCorrupted = this.areOneOrMoreItemsCorrupted.bind(
      this,
    );
    this.getMoreItems = this.getMoreItems.bind(this);
    this.processPgItemPropsForInfoElement = this.processPgItemPropsForInfoElement.bind(
      this,
    );
    this.getExternalInfoRenderers = this.getExternalInfoRenderers.bind(this);
    this.getMoreItemsCalled = false;
    this.itemsDimensions = {};
    this.preloadedItems = {};
    this.itemDimensionsCache = {};
  }

  isFreeArtStore() {
    const { additionalProviderParams } = this.galleryWrapperProps;
    return (
      additionalProviderParams && additionalProviderParams.freeArtStore === true
    );
  }

  getCustomDownloadUrlFuncIfNeeded() {
    if (this.isStoreGallery && this.isFreeArtStore()) {
      return async (mediaUrl, itemId) => {
        const {
          galleryId,
          additionalProviderParams: { srcId },
        } = this.galleryWrapperProps;
        const url = `https://www.wix.com/_api/albums-node-server/getSecuredFileUrl?galleryId=${galleryId}&instanceId=${srcId}&itemId=${itemId}&mediaUrl=${mediaUrl}`;
        const response = await fetch(url, { method: 'GET' });
        const data = await response.json();
        return data[0].path;
      };
    } else {
      return undefined;
    }
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  pgItemsProps() {
    return {
      items: this.galleryWrapperProps.items,
      totalItemsCount: this.galleryWrapperProps.totalItemsCount,
    };
  }

  areOneOrMoreItemsCorrupted(items) {
    return items.some(this.isInvalidItem);
  }

  isInvalidItem(item) {
    // for future validations add more conditions
    const containsItemId = item.itemId === undefined;
    return containsItemId;
  }

  getMoreItems(from) {
    if (this.galleryWrapperProps.getMoreItems) {
      this.getMoreItemsCalled = true;
      this.galleryWrapperProps.getMoreItems(from);
    }
  }

  // getInitialItemsEstimation(pgStyles) { //TODO - this code should move to somewhere in the worker.
  //   const min = 5;
  //   const max = 50;
  //   let numOfCols = 1;
  //   let numOfRows = 1;
  //   const isMobile =
  //     this.galleryWrapper.formFactor === GALLERY_CONSTS.formFactor.MOBILE;
  //   switch (pgStyles.galleryLayout) {
  //     // Adaptive layouts / Vertical only layouts
  //     case -1: // EMPTY: -1
  //     case 1: // MASONRY: 1
  //     case 6: // PANORAMA: 6
  //     case 8: // MAGIC: 8
  //     case 10: // BRICKS: 10
  //     case 11: // MIX: 11
  //     case 12: // ALTERNATE: 12
  //       // pgStyles.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
  //       numOfRows = 4;
  //       numOfCols =
  //         (pgStyles.gridStyle !== 0 && pgStyles.numberOfImagesPerRow) ||
  //         (isMobile ? 1 : 5);
  //       break;
  //     case 0: // COLLAGE: 0,
  //     case 2: // GRID: 2,
  //       const scrollDirection =
  //         pgStyles.scrollDirection === undefined
  //           ? GALLERY_CONSTS.scrollDirection.VERTICAL
  //           : pgStyles.scrollDirection;
  //       if (scrollDirection === GALLERY_CONSTS.scrollDirection.VERTICAL) {
  //         numOfRows = 5;
  //         numOfCols =
  //           (pgStyles.gridStyle !== 0 && pgStyles.numberOfImagesPerRow) ||
  //           (isMobile ? 1 : 5);
  //       } else {
  //         numOfRows = pgStyles.numberOfImagesPerCol || 4;
  //         numOfCols = 10;
  //       }
  //       break;
  //     // Horizontal only layouts
  //     case 3: // THUMBNAIL: 3
  //       numOfCols = isMobile ? 5 : 20;
  //       break;

  //     case 4: // SLIDER: 4
  //       numOfCols = 6;
  //       break;

  //     case 5: // SLIDESHOW: 5
  //       break;

  //     case 7: // COLUMN: 7
  //       numOfCols = 10;
  //       break;

  //     case 9: // FULLSIZE: 9
  //       break;

  //     default:
  //       // pgStyles.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
  //       numOfCols = 5;
  //       numOfRows = 5;
  //       break;
  //   }
  //   const res = numOfRows * numOfCols;
  //   return res < min ? min : res > max ? max : res;
  // }

  processPgItemPropsForInfoElement(infoType, pgItemProps) {
    const wrapperProps = {
      viewMode: this.galleryWrapper.siteHelper.parseViewMode(
        this.galleryWrapperProps.viewMode,
      ),
      eventsListener: this.galleryWrapper.syncEventHandler.eventHandler,
      infoType,
    };

    const itemLoveData = {
      ...this.galleryWrapper.state.itemsLoveData[pgItemProps.id],
    };

    return {
      ...pgItemProps,
      ...wrapperProps,
      ...itemLoveData,
      customDownloadUrl: this.getCustomDownloadUrlFuncIfNeeded(),
    };
  }

  shouldTextBeOnRequestedInfoType(
    requestedInfoType,
    titlePlacement,
    isSlideshow,
  ) {
    switch (requestedInfoType) {
      case EXTERNAL_INFO_TYPE.HOVER:
        return GALLERY_CONSTS.hasHoverPlacement(titlePlacement);
      case EXTERNAL_INFO_TYPE.SLIDESHOW:
        return isSlideshow;
      case EXTERNAL_INFO_TYPE.EXTERNAL:
        return (
          GALLERY_CONSTS.hasVerticalPlacement(titlePlacement) ||
          GALLERY_CONSTS.hasHorizontalPlacement(titlePlacement)
        );
      default:
        return true;
    }
  }

  fetchInfoElementIfNeeded() {
    if (utils.isSSR()) {
      return;
    }
    if (!this.infoElement && !this.fetchingInfoElement) {
      this.fetchingInfoElement = true;
      import(
        /* webpackChunkName: "ProGalleryInfoElement" */ '@wix/pro-gallery-info-element'
      ).then((module) => {
        this.fetchingInfoElement = false;
        const { InfoElement } = module;
        this.infoElement = InfoElement;
      });
    }
  }

  initItemActionsIfNeeded(pgItemProps) {
    try {
      if (pgItemProps.styleParams.loveButton) {
        this.galleryWrapper.itemActionsHelper.initItemActions();
      }
    } catch (ex) {}
  }

  renderInfoElement(type, pgItemProps) {
    const InfoElement =
      this.infoElement ||
      (this.shouldTextBeOnRequestedInfoType(
        type,
        pgItemProps.styleParams.titlePlacement,
        pgItemProps.styleParams.isSlideshow,
      )
        ? TextInfoElement
        : null);
    return (
      InfoElement && (
        <InfoElement
          {...this.processPgItemPropsForInfoElement(type, pgItemProps)}
        />
      )
    );
  }

  fetchItemMetadata(pgItemProps) {
    this.fetchInfoElementIfNeeded();
    this.initItemActionsIfNeeded(pgItemProps);
  }

  hoverInfoElement = (pgItemProps) => {
    this.fetchItemMetadata(pgItemProps);
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.HOVER, pgItemProps);
  };

  externalInfoElement = (pgItemProps) => {
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.EXTERNAL, pgItemProps);
  };

  slideshowInfoElement = (pgItemProps) => {
    this.fetchItemMetadata(pgItemProps);
    return this.renderInfoElement(EXTERNAL_INFO_TYPE.SLIDESHOW, pgItemProps);
  };

  getExternalInfoRenderers() {
    return {
      customHoverRenderer: this.hoverInfoElement,
      customInfoRenderer: this.externalInfoElement,
      customSlideshowInfoRenderer: this.slideshowInfoElement,
    };
  }

  isDimensionless(item) {
    const metaData = item.metaData || item.metadata;
    try {
      if (!metaData.height || !metaData.width) {
        return true;
      }
      if (metaData.height <= 1 || metaData.width <= 1) {
        return true;
      }
    } catch (e) {
      console.error('corrupt item, cant check for dimensions', item, e);
    }
    return false;
  }

  preloadItem(item, onload) {
    if (!item || !item.itemId || !item.mediaUrl) {
      return;
    }
    try {
      const id = item.itemId;
      if (this.itemsDimensions[id]) {
        return; // already measured
      }
      if (typeof this.preloadedItems[id] !== 'undefined') {
        return;
      }
      this.preloadedItems[id] = new Image();
      if (utils.isVerbose()) {
        console.log('Preloading item #' + item);
      }

      this.preloadedItems[id].src = item.mediaUrl;

      if (typeof onload === 'function') {
        this.preloadedItems[id].onload = (e) => {
          onload(e);
        };
      }
      return this.preloadedItems[id];
    } catch (e) {
      console.error('Could not preload item', item, e);
      return;
    }
  }

  addSentDimensionsToCache(itemDimensions) {
    itemDimensions.forEach((dimensions) => {
      this.itemDimensionsCache[dimensions.mediaUrl] = dimensions;
    });
  }

  loadItemsDimensionsIfNeeded() {
    if (utils.isSSR()) {
      return;
    }
    if (
      !(
        this.galleryWrapperProps.items &&
        this.galleryWrapperProps.items.length > 0
      )
    ) {
      return;
    }

    const { items } = this.galleryWrapperProps;

    const itemsWithoutDimensions = items.filter((item) => {
      try {
        return (
          !this.itemDimensionsCache[item.mediaUrl] && this.isDimensionless(item) // if this was already measured and sent to the worker (might not be present yet on items)
        );
      } catch (e) {
        return false;
      }
    });

    if (!itemsWithoutDimensions.length) {
      return;
    }

    const itemPromises = itemsWithoutDimensions.map(() => {
      return new Deferred();
    });

    itemsWithoutDimensions.forEach((item, idx) => {
      const itemPromise = itemPromises[idx];
      this.preloadItem(item, (e) => {
        try {
          if (utils.isVerbose()) {
            console.log('item loaded event', e);
          }
          const ele = e.srcElement;
          const itemId = item.itemId;
          const mediaUrl = item.mediaUrl;

          const itemDim = {
            width: ele.width,
            height: ele.height,
            measured: true,
            itemId,
            mediaUrl,
          };

          this.itemsDimensions[itemId] = itemDim;
          itemPromise.resolve(this.itemsDimensions[itemId]);
        } catch (_e) {
          console.error('Could not calc element dimensions', _e);
        }
      });
    });

    Promise.all(itemPromises.map((deffered) => deffered.promise)).then(
      (itemDimensions) => {
        this.galleryWrapper.clientSideFunctionsConnectedPromise.promise.then(
          () => {
            // This makes sure we are not calling worker functions before they were set in the client side worker
            this.galleryWrapperProps.onItemDimensionsMeasure(itemDimensions);
            this.addSentDimensionsToCache(itemDimensions);
          },
        );
      },
    );
  }
}
